<script setup>
import { onMounted, ref } from 'vue';

defineProps({
  modelValue: String,
  disabled: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
  if (input.value.hasAttribute('autofocus')) {
    input.value.focus();
  }
});

defineExpose({ focus: () => input.value.focus() });
</script>

<template>
  <input
    class="border-slate-300 focus:border-blue-600 focus:ring-blue-600 rounded-lg shadow-sm sm:text-sm disabled:opacity-50 disabled:bg-slate-100 disabled:cursor-not-allowed"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    ref="input"
    :disabled="disabled"
  />
</template>
